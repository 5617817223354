import { React, useState, useEffect, useRef } from 'react';
import { useSelector, useStore } from 'react-redux';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { reduxSelectFieldValue, reduxSelectValueFor } from '../redux/selectors';
import { maybePopulateField } from '../redux/common';
//import i18n from 'i18next';


export default function TelefonField(props) {
    const store = useStore();
    
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');

    const isRequired = (typeof (props.isRequired) === 'undefined' || props.isRequired === false) ? false : true;
    const initIsReadOnly = (typeof (props.isReadOnly) === 'undefined' || props.isReadOnly === false) ? false : true;
    const [isReadOnly, setIsReadOnly] = useState(initIsReadOnly);
    
    const [inputClasses, setInputClasses] = useState(initIsReadOnly ? 'valid' : 'validate');
    const mobitelInput = useRef(null);

    const {t, i18n} = useTranslation(['translation', 'validation', 'flashes']);

    const handleChange = (event) => {
        props.onChange(event);
    };

    useEffect(() => {
        let storeData = store.getState();
        maybePopulateField(
            mobitelInput,
            reduxSelectValueFor(storeData, mobitelInput)
        );
    }, []);

    return (

        <div className="input-field col s6">
            <input ref={mobitelInput} id={fidPrefix + "telefon"} type="text" name={fidPrefix + "telefon"} pattern="[0-9]{9}" required={isRequired} className={inputClasses} readOnly={isReadOnly} onChange={handleChange} />
            <label htmlFor={fidPrefix + "telefon"}><Trans>field.label.phone</Trans></label>
            <span className="helper-text" data-error={i18n.t('validation:field.validate.phoneRequired')} data-success=""></span>
        </div>
    );
}
