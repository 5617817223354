import { parseFieldName } from '../../app.base/redux/common';
import privolitvenaFinmartPrivolitevReducer  from './privolitvenaFinmartPrivolitevReducer';
import { PRIVOLITVENA_FINMART_UPDATED, PRIVOLITVENA_FINMART_PRIVOLITEV_UPDATED, PRIVOLITVENA_FINMART_PRIVOLITEV_Q33_UPDATED } from '../types/privolitvenaFinmartType';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    }, fieldInfo;
    switch (action.type) {
        case PRIVOLITVENA_FINMART_UPDATED:
            fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;

        case PRIVOLITVENA_FINMART_PRIVOLITEV_Q33_UPDATED:
        case PRIVOLITVENA_FINMART_PRIVOLITEV_UPDATED:
            let privolitev = {};
            if (typeof (state.privolitev) !== 'undefined') {
                privolitev = state.privolitev;
            }

            return { ...state, privolitev: privolitvenaFinmartPrivolitevReducer(privolitev, action) };

        default:
            return state;
    }
};


