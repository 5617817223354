import { configureStore, createStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux'
import metaReducer from './reducers/metaReducer';
import zavarovalecReducer from './reducers/zavarovalecReducer';
import rootLegalReducer from './reducers/rootLegalReducer';
import privolitvenaFinmartReducer from './reducers/privolitvenaFinmartReducer';
import otpReducer from './reducers/otpReducer';
//import zavarovanaOsebaReducer from './reducers/zavarovanaOsebaReducer';
//import produktReducer from './reducers/produktReducer';
//import idd2Reducer from './reducers/idd2Reducer';
//import otherReducer from './reducers/otherReducer';
//import izjavaReducer from './reducers/izjavaReducer';
//import gdprReducer from './reducers/gdprReducer';
//import privolitvenaEurekoReducer from './reducers/privolitvenaEurekoReducer';
//import sepaReducer from './reducers/sepaReducer';
//import rootPonudbaReducer from './reducers/rootPonudbaReducer';
//import nacinPlacilaReducer from './reducers/nacinPlacilaReducer';

const preloadedState = {
    meta: {
        agentParams: '',
        jobSessionId: ''
    },
    legal: {
        privolitvenaFinmart: {
            privolitev: {
                q33: {
                    klicPoTelefonu: false,
                    posta: false,
                    elektronskaPosta: false,
                    SMS: false,
                    drugePoti: false
                }
            }
        }
    }
};

/*const rootReducer = combineReducers({
    rootPonudba: rootPonudbaReducer,

    zavarovalec: zavarovalecReducer,

    produkt: combineReducers({
        rootProdukt: produktReducer,
        zavarovanaOseba: zavarovanaOsebaReducer,  // prefix zavarovanec_ , premija prefix: zavarovanec_premija_
        opredelitevPotrebInZahtev: idd2Reducer,  // prefix idd2_ (used on fields), but the structure is: produkt_opredelitevpotrebinzahtev_
        nacinPlacila: nacinPlacilaReducer,
    }),

    privolitvenaEureko: privolitvenaEurekoReducer,

    sepa: sepaReducer,

    meta: metaReducer,


});*/


export default configureStore({
    reducer: {

        otp: otpReducer,
        rootLegal: rootLegalReducer,
        legal: combineReducers({
            zavarovalec: zavarovalecReducer,
            privolitvenaFinmart: privolitvenaFinmartReducer
        }),

        meta: metaReducer,
    },
    preloadedState
});


