import { parseFieldName } from '../../app.base/redux/common';
import { OTP_UPDATED } from '../types/otpType';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    };
    switch (action.type) {
        case OTP_UPDATED:
            let fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;
        default:
            return state;
    }
};