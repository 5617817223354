import { React, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation, } from "react-router-dom";
import { useStore, useDispatch, useSelector } from 'react-redux'
import M from '@materializecss/materialize';
import { useTranslation } from 'react-i18next';
import { getActionType, parseFieldName, inputValueToUpperCase } from '../app.base/redux/common';
import ImeField from '../app.base/field/ImeField';
import EmailField from '../app.base/field/EmailField';
import MobitelField from '../app.base/field/MobitelField';
import TelefonField from '../app.base/field/TelefonField';
import NaslovField from '../app.base/field/NaslovField';
import DaNeField from '../app.base/field/DaNeField';
import PrenosPodatkovField from '../app.base/field/PrenosPodatkovField';
import KrajPodpisaField from '../app.base/field/KrajPodpisaField';
import FormSubmitField from '../app.base/field/FormSubmitField';
import AgreeField from '../app.base/field/AgreeField';
import HiddenField from '../app.base/field/HiddenField';
import PodpisField from '../app.base/field/PodpisField';
import FormStepButtonField from '../app.base/field/FormStepButtonField';
import PredogledArea from '../app.base/other/PredogledArea';
import PotrditvenaKodaField from '../field/PotrditvenaKodaField';
import ItemClen545Service from '../service/ItemClen545Service';
import { META_UPDATED } from '../redux/types/metaType';
import { reduxSelectMetaAgentParams } from '../app.base/redux/selectors';


export default function VstopSSvetovanjemStrankaPrisotnaForm(props) {

    

    /**
     * @todo rename to selectAgentParams to follow the convention and show that this redux selector.
     */
    const agentParams = useSelector(reduxSelectMetaAgentParams);
    const selectZavarovalecEmail = useSelector((state) => {
        if (typeof (state.legal) === 'undefined') {
            return null;
        }
        else if (typeof (state.legal.zavarovalec) === 'undefined') {
            return null;
        }
        else if (typeof (state.legal.zavarovalec.email) === 'undefined') {
            return null;
        }

        return state.legal.zavarovalec.email;
    });

    const selectZavarovalecMobitel = useSelector((state) => {
        if (typeof (state.legal) === 'undefined') {
            return null;
        }
        else if (typeof (state.legal.zavarovalec) === 'undefined') {
            return null;
        }
        else if (typeof (state.legal.zavarovalec.mobitel) === 'undefined') {
            return null;
        }

        return state.legal.zavarovalec.mobitel;
    });

    const selectIsEmailRequired = useSelector((state) => {
        if (typeof(state.meta) === 'undefined') {
            return false;
        }
        else if (typeof(state.meta.soglasjePrejemDokumentacije) === 'undefined' || state.meta.soglasjePrejemDokumentacije == false) {
            return false;
        }

        return true;
    });

    const selectIsKanalRequired = useSelector((state) => {
        if (typeof(state.legal) === 'undefined') {
            return false;
        }
        else if (typeof(state.legal.privolitvenaFinmart) === 'undefined') {
            return false;
        }
        else if (typeof(state.legal.privolitvenaFinmart.privolitev) === 'undefined') {
            return false;
        }

        const gdpr = state.legal.privolitvenaFinmart.privolitev.q31 ?? 'N';
        const trzenje = state.legal.privolitvenaFinmart.privolitev.q32 ?? 'N';
        const komuniciranje = state.legal.privolitvenaFinmart.privolitev.q33 ?? {};

        // Nothing else to check.
        if (trzenje === 'N' && gdpr === 'N') {
            return false;
        }

        if (komuniciranje.klicPoTelefonu) {
            return false;
        }

        if (komuniciranje.posta) {
            return false;
        }

        if (komuniciranje.elektronskaPosta) {
            return false;
        }

        if (komuniciranje.SMS) {
            return false;
        }

        if (komuniciranje.drugePoti) {
            return false;
        }

        return true;
    });



    const [status, setStatus] = useState('');
    const [otpId, setOtpId] = useState(null);
    const [pdfContent, setPdfContent] = useState(null);
    const [signedPdfContent, setSignedPdfContent] = useState(null);
    const [kopirajPodatke, setKopirajPodatke] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const store = useStore();
    const dispatch = useDispatch();

    // Form steps.
    const formStepsEl = useRef(null);
    const [allSteps, setAllSteps] = useState([
        {
            step: 1,
            tab: 'zavarovalec-data'
        },
        {
            step: 2,
            tab: 'izjava'
        },
        {
            step: 3,
            tab: 'form-final'
        }
    ]);
    const [currentStep, setCurrentStep] = useState(1);

    const { t, i18n } = useTranslation(['translation', 'validation', 'flashes', 'backend']);

    const reInitPrenosPodatkov = () => {
        setKopirajPodatke(false);
        setKopirajPodatke(true);
    }

    const handleFieldChange = (event, valueToUpperCase=true) => {
        
        // Find prefix.
        let fieldInfo = parseFieldName(event.target.name);
        let type = '', name = event.target.name;
        let normalizedValue = event.target.value;
        if (valueToUpperCase) {
            normalizedValue = inputValueToUpperCase(normalizedValue);
        }

        if (fieldInfo.prefix.length) {
            
            type = getActionType(fieldInfo.prefix, 'UPDATED');
            dispatch({
                type: type,
                payload: {
                    field: event.target.name,
                    value: normalizedValue
                }
            });
        }
    };

    const handleFieldChangeAndCopyToFinmart = (event, valueToUpperCase = true) => {

        handleFieldChange(event, true);
        // Find prefix.
        /*let fieldInfo = parseFieldName(event.target.name);
        let finmartFieldName = 'privolitvenafinmart_' + fieldInfo.name;
        let dummyEvent = {
            target: {
                name: finmartFieldName,
                value: event.target.value
            }
        };
        handleFieldChange(dummyEvent, true);*/

        reInitPrenosPodatkov();

        



    };

    const handleFieldChangeRaw = (event) => {
        handleFieldChange(event, false);
    };

    const handleOtpIdChange = (value) => {
        setOtpId(value);

        let type = getActionType('otp_', 'UPDATED');
        dispatch({
            type: type,
            payload: {
                field: 'id',
                value: value
            }
        });
    };

    const handlePodpisFieldChange = (fieldName, canvasAsString) => {
        const dummyEvent = {
            target: {
                name: fieldName,
                value: canvasAsString
            }
        };

        handleFieldChange(dummyEvent, false);
    };

    const handlePreviewClick = (requestId) => {
        console.debug("Handle preview click form...");

        if (!validateForm()) {
            M.toast({ html: t('validation:form.validate.preSubmit.failed') });
            return;
        }

        let itemClen545Service = new ItemClen545Service();
        let requestBody = buildRequest(requestId);
        itemClen545Service.sendItemClen545(requestBody)
            .then((result) => {
                if (result.success) {
                    switch(requestId) {
                        case 'preview-without-signatures':
                            setPdfContent(result.data.pdf.content);
                            break;
                        case 'preview-with-signatures':
                            setSignedPdfContent(result.data.pdf.content);
                            break;
                        
                    }
                }
                else {
                    M.toast({ html: t("flashes:form.error.preview") });
                    M.toast({ html: t(result.message) });
                }
            })
            .then((result) => {
            })
            .catch(error => {
                M.toast({ html: t("flashes:form.error.preview") });
            });
    }

    const buildRequest = (requestId) => {
        let storeData = store.getState();
        let requestBody = {
            form: {
                type: 'VstopSSvetovanjemStrankaPrisotnaForm',
                action: 'preview',
            },
            legal: {
                type: 'EurekoLegal',
                data: {
                    rootLegal: storeData.rootLegal,
                    legal: storeData.legal,
                    meta: storeData.meta
                }
            },
            otp: {
                type: 'AppTypeOtp',
                data: {
                    ...storeData.otp
                }
            }
        };
        
        if (requestId === 'submit') {
            requestBody.form.action = 'submit';
        }

        return requestBody;
    };

    const validateFormSignatures = () => {
        let elements = document.querySelectorAll('input.eureko-signature'), el = null;
        let i = 0, isValid = true, gIsValid = true, clInvalidClass = 'eureko-signature-1-invalid', contains = null;


        while (i < elements.length) {

            el = elements[i].closest('div.eureko-signature');
            contains = el.classList.contains(clInvalidClass);


            isValid = elements[i].value === '1';
            if (gIsValid && !isValid) {
                // At least one field failed, entire form validation failed.
                gIsValid = false;
            }

            if (isValid && contains) {
                el.classList.remove(clInvalidClass);
            }
            else if (!isValid && !contains) {
                el.classList.add(clInvalidClass);
            }

            i++;
        }

        return gIsValid;
    };

    const validateFormInputs = () => {

        let customValidators = ['davcnaStevilka', 'IBAN', 'nazivBanke'];

        let elements = null, cssSelector, i = 0, j = 0;
        let clNameAdd, clNameRemove, formValid = true;

        /**
         * @todo improve
         */
        let tabValidClasses = 'blue-grey', tabInvalidClasses = 'red';
        let tabs = document.querySelectorAll('#form-steps li'), tabValid;

        while (j < allSteps.length) {
            cssSelector = '#' + allSteps[j].tab + ' input';
            elements = document.querySelectorAll(cssSelector);
            tabValid = true
            i = 0;

            while (i < elements.length) {

                clNameAdd = 'valid';
                clNameRemove = 'invalid';

                // We do not know how to trigger validators on fields with custom validation logic.
                // so for now we use this.
                if (customValidators.some((v) => elements[i].name.indexOf(v) !== -1) && elements[i].classList.contains('invalid')) {

                    // Field is already marked as invalid.
                    tabValid = false;
                    formValid = false;
                    i++;
                    continue;
                }
                else if (!elements[i].checkValidity()) {
                    clNameAdd = 'invalid';
                    clNameRemove = 'valid';
                    tabValid = false;
                    formValid = false;
                }

                if (!elements[i].classList.contains(clNameAdd)) {
                    elements[i].classList.add(clNameAdd);
                }

                if (elements[i].classList.contains(clNameRemove)) {
                    elements[i].classList.remove(clNameRemove);
                }

                switch (elements[i].type) {
                    case 'radio':
                    case 'checkbox':
                        let parent = elements[i].parentElement, cl = 'eureko-input-boolean-required';
                        if (!elements[i].checkValidity()) {
                            parent.classList.add(cl);
                        }
                        else {
                            parent.classList.remove(cl);
                        }
                        break;

                    default:
                        break;
                }

                i++;
            }

            // Mark tab if validation error found.
            if (!tabValid) {
                tabs[j].classList.remove(tabValidClasses);

                if (!tabs[j].classList.contains(tabInvalidClasses)) {
                    tabs[j].classList.add(tabInvalidClasses);
                }
            }
            else {
                // valid tab
                tabs[j].classList.remove(tabInvalidClasses);
                if (!tabs[j].classList.contains(tabValidClasses)) {
                    tabs[j].classList.add(tabValidClasses);
                }
            }

            j++;
        }
        return formValid;
    };

    const validatePrivolitev = () => {
        const storeData = store.getState();
        const gdpr = storeData.legal.privolitvenaFinmart.privolitev.q31;
        const trzenje = storeData.legal.privolitvenaFinmart.privolitev.q32;
        const komuniciranje = storeData.legal.privolitvenaFinmart.privolitev.q33;

        // Nothing else to check.
        if (trzenje === 'N' && gdpr === 'N') {
            return true;
        }

        if (komuniciranje.klicPoTelefonu) {
            return true;
        }

        if (komuniciranje.posta) {
            return true;
        }

        if (komuniciranje.elektronskaPosta) {
            return true;
        }

        if (komuniciranje.SMS) {
            return true;
        }

        if (komuniciranje.drugePoti) {
            return true
        }


        return false;
    };

    const validateForm = () => {
        let sig = validateFormSignatures(), inp = validateFormInputs();
        let privolitevValidated = false;
        
        if (sig && inp) {
            privolitevValidated = validatePrivolitev();
            if (!privolitevValidated) {
                M.toast({ html: "validation:form.validate.finmartPrivolitev" });
            }
        }

        return (privolitevValidated);
    }

     const handleSubmit = async (event) => {
         event.preventDefault();
         setStatus('SUBMITTING');
         if (!validateForm()) {
            M.toast({ html: t('validation:form.validate.preSubmit.failed')});
            setStatus('');
            return;
        }

        let itemClen545Service = new ItemClen545Service();
        let requestBody = buildRequest('submit');
        itemClen545Service.sendItemClen545(requestBody)
            .then((result) => {
                if (result.success) {
                    dispatch({
                        type: META_UPDATED,
                        payload: {
                            field: 'meta_agentParams',
                            value: result.data.agentParams
                        }
                    });

                    dispatch({
                        type: META_UPDATED,
                        payload: {
                            field: 'meta_jobSessionId',
                            value: result.data.jobSessionId
                        }
                    });

                    M.toast({ html: t("flashes:submission.successful") });
                    setStatus('SUBMITTED');
                }
                else {
                    setStatus('');
                    M.toast({html: t(result.message) });
                }
            })
            .catch(error => {
                setStatus('');
                /*console.debug("catch ...");
                console.debug(error);*/
                M.toast({ html: t("flashes:submission.error") });
                //M.toast({ html: t("backend:" + result.message) });
            })
    }

    // form-steps actions
    const handleStepPreviousClick = (event) => {

        console.debug('previous click');
        let cs = currentStep;
        if (cs > 1) {
            cs--;
            setCurrentStep(cs);
            let index = cs - 1;
            var instance = M.Tabs.getInstance(document.getElementById('form-steps'));
            instance.select(allSteps[index].tab);
            window.scroll(0, 0);
        }

        //reInitPrenosPodatkov();
    };

    const handleStepNextClick = (event) => {
        console.debug('next click');
        let cs = currentStep;
        if (cs < allSteps.length) {
            cs++;
            setCurrentStep(cs);
            let index = cs - 1;
            console.debug(allSteps[index].tab);
            var instance = M.Tabs.getInstance(document.getElementById('form-steps'));
            instance.select(allSteps[index].tab);
            window.scroll(0, 0);
        }

        // Uncomment this during dev. when necessary.
        //handleSubmit('submit')

        //reInitPrenosPodatkov();
    };

    const handleStepTabClick = (event) => {
        let tabStringId = event.target.getAttribute('href').slice(1), i = 0;

        while (i < allSteps.length && allSteps[i].tab !== tabStringId) {
            i++;
        }

        if (i < allSteps.length) {
            setCurrentStep(allSteps[i].step);
        }

        //reInitPrenosPodatkov();
    }

    // On successful submission redirect to ty page.
    useEffect(() => {
        if (status === 'SUBMITTED') {
            navigate('/zahtevek-za-sklepanje');
        }
    }, [status, navigate]);

    useEffect(() => {
        M.Tabs.init(document.getElementById('form-steps'), {
            swipeable: false
        });

    }, []);

    return (
        <div>

            <form onSubmit={handleSubmit} noValidate={true}>
                <div className="row">
                    <div className="col s12">
                        <ul ref={formStepsEl} className="tabs" id="form-steps">
                            <li className="tab col s4 blue-grey darken-1"><a className="white-text" href="#zavarovalec-data" onClick={handleStepTabClick}>Zavarovalec</a></li>
                            <li className="tab col s4 blue-grey darken-1"><a className="white-text" href="#izjava" onClick={handleStepTabClick}>Izjava - Finmart</a></li>
                            <li className="tab col s4 blue-grey darken-1"><a className="white-text" href="#form-final" onClick={handleStepTabClick}>Oddaja</a></li>
                        </ul>
                    </div>
                </div>

                <div className="row">

                    <div id="zavarovalec-data" className="col s12">
                        <HiddenField prefix="meta_" name="agentParams" onChange={handleFieldChangeRaw} value={agentParams} />
                        <HiddenField prefix="meta_" name="jobSessionId" onChange={handleFieldChangeRaw} value="" />

                        <fieldset>
                            <legend>Zavarovalec</legend>
                            <div className="row">
                                <ImeField prefix="zavarovalec_" onImeChange={handleFieldChangeAndCopyToFinmart} onPriimekChange={handleFieldChangeAndCopyToFinmart} />
                            </div>

                            <div className="row">
                                <EmailField prefix="zavarovalec_" onChange={handleFieldChangeAndCopyToFinmart} isRequired={selectIsEmailRequired} />
                                <MobitelField prefix="zavarovalec_" onChange={handleFieldChangeAndCopyToFinmart} isRequired={true} />
                            </div>
                            <PotrditvenaKodaField prefix="otp_" onChange={handleFieldChange} onOtpIdChange={handleOtpIdChange} formStatus={status} email={selectZavarovalecEmail} mobitel={selectZavarovalecMobitel} otp={{ id: otpId }} isRequired={true} />
                        </fieldset>
                        
                        <fieldset>
                            <legend>Sklepanje</legend>
                            <div className="row">
                                <div className="input-field col s12">
                                    <AgreeField prefix="meta_" name="soglasjeUporabaEmailMobitel" onChange={handleFieldChange}
                                        isRequired={true}
                                        text="Z namenom preverjanja podatkov bo stranka na telefonsko številko in/ali e-poštni naslov prejela verifikacijsko kodo, ki jo je za nadaljevanje sklepanja potrebno vnesti v sklepalno aplikacijo."
                                    />
                                    <AgreeField prefix="meta_" name="soglasjePrejemDokumentacije" onChange={handleFieldChange}
                                        isRequired={false}
                                        text="Stranka se strinja, da bo sklepalno dokumentacijo prejela po elektronski pošti in da se lahko z njo komunicira elektronsko. In sicer po elektronski pošti, telefonu ali preko mobilnih in spletnih aplikacij. Stranka je seznanjena, da lahko to soglasje kadarkoli umakne s pisno izjavo, ki jo pošlje po navadni ali elektronski pošti."
                                    />
                                </div>
                            </div>
                        </fieldset>
                        
                        <fieldset>
                            <legend>Podpis za 545. člen</legend>
                            <PodpisField prefix="rootlegal_" signatureFor="agent" updateFormDataField="podpisSvetovalec" onPodpisFieldChange={handlePodpisFieldChange} />
                            <PodpisField prefix="rootlegal_" signatureFor="customer" updateFormDataField="podpisZavarovalec" onPodpisFieldChange={handlePodpisFieldChange} />
                        </fieldset>

                    </div>

                    <div id="izjava" className="col s12">
                        <div className="hide">
                            <PrenosPodatkovField
                                prefix="meta_"
                                name="zavarovalecPrenosPodatkov"
                                kopirajPodatke={kopirajPodatke}
                                onChange={handleFieldChangeRaw}
                                texts={{
                                    'legendText': "Prenos podatkov zavarovalca",
                                    'neKopirajText': "ne",
                                    'kopirajText': "da"
                                }}

                                mapFields={[
                                    {
                                        src: 'zavarovalec_ime',
                                        dst: 'privolitvenafinmart_ime'
                                    },
                                    {
                                        src: 'zavarovalec_priimek',
                                        dst: 'privolitvenafinmart_priimek'
                                    },
                                    {
                                        src: 'zavarovalec_email',
                                        dst: 'privolitvenafinmart_email'
                                    },
                                    {
                                        src: 'zavarovalec_mobitel',
                                        dst: 'privolitvenafinmart_mobitel'
                                    }
                                ]}
                            />
                        </div>
                        
                        <fieldset>
                            <legend>Varstvo osebnih podatkov</legend>
                            <p>
                                Finmart, zavarovalno zastopniška družba, d.o.o., Leskoškova cesta 9E, 1000 Ljubljana, telefonska številka 080 20 20, e-naslov svetovanje@finmart.si, vas v skladu z veljavno zakonodajo na področju varstva osebnih podatkov v nadaljevanju seznanja z osnovnimi informacijami glede zbiranja in obdelovanja vaših osebnih podatkov.
                            </p>
                        </fieldset>
                        <fieldset>
                            <legend>Osebni podatki</legend>
                            <div className="row">
                                <ImeField prefix="privolitvenafinmart_" onImeChange={handleFieldChange} onPriimekChange={handleFieldChange} isReadOnly={true} isRequired={true} />
                            </div>
                            <div className="row">
                                <EmailField prefix="privolitvenafinmart_" onChange={handleFieldChange} isReadOnly={true} isRequired={selectIsEmailRequired} />
                                <MobitelField prefix="privolitvenafinmart_" onChange={handleFieldChange} isReadOnly={true} isRequired={true} />
                            </div>
                            <div className="row">
                                <TelefonField prefix="privolitvenafinmart_" onChange={handleFieldChange} isRequired={false} />
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>Naslov prebivališča</legend>
                            <NaslovField prefix="zavarovalec_naslov_" onChange={handleFieldChange} />
                        </fieldset>
                        <fieldset>
                            <legend>Privolitev za obdelavo osebnih podatkov</legend>
                            <p>
                                S podpisom te privolitvene izjave spodaj podpisani soglašam, da se moji osebni podatki, navedeni na tej privolitveni izjavi in ostali osebni podatki, razen posebnih vrst osebnih podatkov (npr. podatki o zdravstvenem stanju, ipd), ki jih je družba Finmart d.o.o., Leskoškova cesta 9E, 1000 Ljubljana, pridobila v postopku sklepanja in izvajanja zavarovalnih pogodb, obdeluje oziroma jih bo obdelovala za:
                            </p>
                            <DaNeField prefix="privolitvenafinmart_privolitev_"
                                stringId="q31"
                                question="Segmentirano neposredno trženje na podlagi profilov, kar pomeni oblikovanje meni prilagojenih ponudb različnih zavarovalnih produktov, ki jih lahko ter drugih novostih s področja zavarovalništva in finančnih storitev ter vabil na dogodke na podlagi ocenjevanja in predvidevanja mojih interesov, ekonomskega položaja, potreb, ipd."
                                onChange={handleFieldChange}>
                            </DaNeField>
                            <DaNeField prefix="privolitvenafinmart_privolitev_"
                                stringId="q32"
                                question="Posredovanje osebnih podatkov, vključno z že oblikovanimi profili družbam, ki so Finmartovi vsakokratni pogodbeni partnerji za zavarovalno zastopanje in posredovanje (npr. družba IN.NET d.o.o. in družba FINMART DIREKT d.o.o.) in družbam, ki tržijo produkte in storitve pod blagovno znamko Dr Best (npr. družba BD Asistenca d.o.o. in družba EUREKO d.o.o.), na podlagi česar bodo le-te družbe lahko oblikovale ter vas seznanjale s svojimi, vam prilagojenimi ponudbami. Seznam vseh družb je na vpogled na sedežu družbe Finmart d.o.o."
                                onChange={handleFieldChange}>
                            </DaNeField>
                            <p>
                                Za zgoraj navedene namene vas lahko kontaktiramo preko:
                            </p>
                            <AgreeField prefix="privolitvenafinmart_privolitev_q33_" name="klicPoTelefonu" onChange={handleFieldChange}
                                isRequired={selectIsKanalRequired}
                                text="klica po telefonu"
                            />
                            <AgreeField prefix="privolitvenafinmart_privolitev_q33_" name="posta" onChange={handleFieldChange}
                                isRequired={selectIsKanalRequired}
                                text="pošte"
                            />
                            <AgreeField prefix="privolitvenafinmart_privolitev_q33_" name="elektronskaPosta" onChange={handleFieldChange}
                                isRequired={selectIsKanalRequired}
                                text="elektronske pošte"
                            />
                            <AgreeField prefix="privolitvenafinmart_privolitev_q33_" name="SMS" onChange={handleFieldChange}
                                isRequired={selectIsKanalRequired}
                                text="sporočil po telefonu (SMS)"
                            />
                            <AgreeField prefix="privolitvenafinmart_privolitev_q33_" name="drugePoti" onChange={handleFieldChange}
                                isRequired={selectIsKanalRequired}
                                text="drugih poti"
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Kategorije uporabnikov osebnih podatkov</legend>
                            <p>
                                Poleg zaposlencev družbe Finmart, vaše osebne podatke obdelujejo pogodbeni obdelovalci (npr. zavarovalni zastopniki, za zagotovitev legitimnih interesov (npr. IT strokovnjaki), ter druge osebe, kadar imajo zakonsko podlago za pridobitev in obdelavo osebnih podatkov (npr. nadzorni organi).
                            </p>
                        </fieldset>
                        <fieldset>
                            <legend>Namen obdelave in pravni temelj obdelave osebnih podatkov</legend>
                            <p>
                                Osebni podatki se lahko obdelujejo za sklepanje in izvajanje zavarovalnih pogodb, to so osebni podatki na ponudbi oziroma polici in drugi podatki, ki jih v postopku sklenitve in izvajanja zavarovanj pridobimo od zavarovalca in tretjih oseb za namen izvajanja zavarovalne pogodbe, v skladu z npr. Zakonom o zavarovalništvu. Osebni podatki se lahko obdelujejo na podlagi drugih zakonov, npr. Zakon o preprečevanju denarja in financiranja terorizma, mednarodnih pogodb, predpisov EU in nacionalnih zakonov, ki terjajo, da se osebni podatki posameznikov posredujejo državnim organom in drugim upravljavcem za izpolnjevanje njihovih zakonskih obveznosti ali pristojnosti.
                            </p>
                            <p>
                                Osebni podatki se lahko obdelujejo na podlagi vaše privolitvene izjave iz poglavja Privolitev za obdelavo osebnih podatkov.
                            </p>
                            <p>
                                Osebni podatki se lahko obdelujejo na podlagi zakonitega interesa (upravljavca) obdelovalca osebnih podatkov, če ne prevladajo interesi ali temeljne pravice in svoboščine posameznika, na katerega se nanašajo osebni podatki, pri čemer se upoštevajo razumna pričakovanja posameznikov v zvezi z obdelavo osebnih podatkov, glede na njihovo razmerje do Finmart d.o.o.. Finmart d.o.o. osebne podatke obdeluje na podlagi legitimnega interesa za namene izboljšave, razvoja in nadgradnje storitev, priprave na prodajni razgovor, za izvajanje obračunov in kontrole provizij, za izvajanje neposrednega trženja, pri čemer uporablja podatke, ki jih je pridobil pri sklepanju ali izvajanju zavarovalne pogodbe. Posameznik lahko tovrstni obdelavi ugovarja na način iz poglavja Pravice posameznika. Osebni podatki se na tej podlagi lahko obdelujejo za zagotovitev informacijske varnosti in delovanja informacijskega sistema.
                            </p>
                        </fieldset>
                        <fieldset>
                            <legend>Pravice posameznika/pritožba/preklic privolitve</legend>
                            <p>
                                Posameznik ima možnost, da lahko privolitev za obdelavo za zgoraj označene namene kadarkoli začasno ali trajno, delno ali v celoti prekliče oziroma spremeni, zahteva dostop, dopolnitev, popravek, omejitev, prenos, ugovor obdelavi ali izbris osebnih podatkov, kar lahko stori s pisno izjavo, naslovljeno na naslov Finmart d.o.o., Leskoškova cesta 9E, 1000 Ljubljana ali po e-naslovu na svetovanje@finmart.si. Preklic privolitve ne vpliva na zakonitost obdelave, ki se je na podlagi privolitev izvajala do preklica oz.ugovora.Preklic privolitve oz.ugovor obdelavi za neposredno trženje na podlagi legitimnega interesa, ne vpliva na obdelavo osebnih podatkov, ki se obdelujejo na drugih podlagah (zakon, pogodba, zakoniti interes). 
                            </p>
                            <p>
                                Posameznik ima pravico vložiti pritožbo pri Informacijskem pooblaščencu RS, če meni, da se njegovi osebni podatki shranjujejo ali kako drugače obdelujejo v nasprotju z veljavnimi predpisi, ki urejajo varstvo osebnih podatkov.
                            </p>
                        </fieldset>
                        <fieldset>
                            <legend>Hramba osebnih podatkov</legend>
                            <p>
                                Osebne podatke, ki se obdelujejo na podlagi te vaše privolitvene izjave in za zgoraj označene namene, bo Finmart d.o.o. hranil do preklica privolitve z vaše strani. Enako velja za uporabo osebnih podatkov v družbah, navedenih na tej privolitveni izjavi, ki so jim bili osebni podatki posredovani za isti namen obdelave. Ostale osebne podatke, pridobljene v okviru sklenitve ali izvajanja zavarovalne pogodbe ali na podlagi zakona pa Finmart d.o.o. hrani do poteka zakonskega roka hrambe. V drugih primerih je rok hrambe omejen z dosego namena, za katerega se osebni podatki obdelujejo.
                            </p>
                            <p>
                                Finmart d.o.o. bo pridobljene osebne podatke skrbno varoval, tako da bo uporabil organizacijske, tehnične in druge ustrezne postopke in ukrepe za preprečitev nepooblaščenega dostopa, obdelave, uničenja, spremembe ali izgube osebnih podatkov.
                            </p>
                        </fieldset>
                        <fieldset>
                            <legend>Kraj in podpis privolitvene izjave</legend>
                            <KrajPodpisaField prefix="meta_" onChange={handleFieldChange} />
                            <PodpisField prefix="privolitvenafinmart_" signatureFor="customer" updateFormDataField="podpisZavarovalec" onPodpisFieldChange={handlePodpisFieldChange} />
                        </fieldset>
                    </div>
                </div>

                <div id="form-final" className="col s12">
                    <PredogledArea onPreviewClick={handlePreviewClick} requestId="preview-with-signatures" pdfContent={signedPdfContent} />
                    <FormSubmitField formStatus={status} />
                </div>

                <div className="row">
                    <div className="col m4">
                        <FormStepButtonField role="previous" allSteps={allSteps} currentStep={currentStep} onClick={handleStepPreviousClick} />
                    </div>
                    <div className="col m4 offset-m3">
                        <FormStepButtonField role="next" allSteps={allSteps} currentStep={currentStep} onClick={handleStepNextClick} />
                    </div>
                </div>
            </form>
            
        </div>
    );

}


