// import { useEffect } from 'react';

export default function LinkBox(props) {

    return (<>
        <div className="col s12 m4">

            <div className="card center">
                <span className="card-title center">
                    {props.displayName}
                </span>
                <a href={props.url}>
                    <span className="btn-large btn-floating halfway-fab waves-effect waves-light green ">
                        <i className="material-icons">arrow_forward</i>
                    </span>
                </a>

                <div className="card-content center">
                    <img className="eureko-logo-footer" src={props.imageLocation} alt="Link logo" />
                </div>
            </div>

        </div>

    </>);
}