import { React, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation, } from "react-router-dom";
import { useStore, useDispatch, useSelector } from 'react-redux';
import AgentService from "../service/AgentService";
import { META_UPDATED } from '../redux/types/metaType';
import { reduxSelectMetaAgentParams, reduxSelectMetaJobSessionId } from '../app.base/redux/selectors';



export default function GuardProdukt(props) {
    const store = useStore();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectAgentParams = useSelector(reduxSelectMetaAgentParams);
    const selectJobSessionId = useSelector(reduxSelectMetaJobSessionId);

    const [guardStatus, setGuardStatus] = useState(null);

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            setGuardStatus('OK');
        }
        else if (typeof (selectAgentParams) !== 'string' || !selectAgentParams.length || typeof(selectJobSessionId) !== 'string' || !selectJobSessionId.length) {
            navigate('/neveljavna-povezava');
        }
        else {
            setGuardStatus('OK');
        }
    }, [selectAgentParams, selectJobSessionId, navigate]);


    return (
        <>
            {guardStatus === 'OK' && props.children}
        </>
    )
}