import { createSelector } from '@reduxjs/toolkit';


export function reduxSelectState(state) {
    return state;
}


export function reduxSelectFieldRef(state, fieldRef) {
    return fieldRef;
}


export function reduxSelectFieldValue(state, fieldRef) {
    let parts = fieldRef.current.name.split('_'), partState = state, key = '';
    /*console.debug('parts redux....');
    console.debug(parts);*/
    for (let i = 0; i < parts.length; i++) {
        key = parts[i];

        if (typeof (partState[key]) === 'undefined') {
            return null;
        }

        partState = partState[key];
    }

    return partState;

}


export const reduxSelectMetaAgentParams = (state) => {
    return state.meta.agentParams;
};

export const reduxSelectMetaJobSessionId = (state) => {
    return state.meta.jobSessionId;
};

export const reduxSelectValueFor = createSelector([state => state, (state, fieldRef) => fieldRef], reduxSelectFieldValue);


