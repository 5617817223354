class IdentityService {
    /*getUrl(endpoint) {
        let url = process.env.REACT_APP_API_URI;
        switch (endpoint) {
            case 'identitiesCreate':
                url = url + '/post';
                break;
            default:
                url = null;
                break;
        }

        return url;

    }*/

    getUrl(endpoint) {
        let url = process.env.REACT_APP_API_URI;
        switch (endpoint) {
            case 'identitiesCreate':
            case 'identitiesUpdate':
                url = url + '/identities';
                break;
            default:
                url = null;
                break;
        }

        return url;

    }

    /**
     * 
     * @param {object} contactInfo {email: "", mobitel: ""}
     */
    async sendVerificationCode(contactInfo) {
        let url = this.getUrl('identitiesCreate');
        let requestObject = {};

        //if ('email' in contactInfo && contactInfo.email.length) {
            requestObject['email'] = contactInfo.email;
        //}

       // if ('mobitel' in contactInfo && contactInfo.mobitel.length) {
            requestObject['mobitel'] = contactInfo.mobitel;
        //}
        
        let result = {
            status: false,
            message: "Not called yet."
        };
        //try {

            let response = await fetch(url, {
                'method': 'POST',
                'body': JSON.stringify(requestObject),
                'headers': new Headers({ 'content-type': 'application/json' })
            });

            /*if (!response.ok) {
                throw new Error("Response was not OK (HTTP error)!");
            }*/

            return await response.json();

        /*} catch (error) {
            result = {
                status: false,
                message: error.message
            };
        } finally {
            return result;
        }*/
        

    }

    async checkVerificationCode(contactInfo) {
        let url = this.getUrl('identitiesUpdate');
        let requestObject = {
            potrditvenaKoda: contactInfo.potrditvenaKoda,
            id: contactInfo.id
        };

        if ('email' in contactInfo) {
            requestObject['email'] = contactInfo.email;
        }

        if ('mobitel' in contactInfo) {
            requestObject['mobitel'] = contactInfo.mobitel;
        }

        //try {

            let response = await fetch(url, {
                'method': 'PATCH',
                'body': JSON.stringify(requestObject),
                'headers': new Headers({ 'content-type': 'application/json' })
            });

            /*if (!response.ok) {
                throw new Error("Response was not OK (HTTP error)!");
            }*/

            return await response.json();

        /*} catch (error) {
            result = {
                status: false,
                message: error.message
            };
        } finally {
            return result;
        }*/

    }
}

export default IdentityService;