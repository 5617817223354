
import { React, useState, useEffect, useRef } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
//import i18n from 'i18next';

export default function KrajPodpisaField(props) {
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const  handleChange = (event) => {
        props.onChange(event);
    };

    return (
        <div className="input-field col s12">
            <input id={fidPrefix + "krajPodpisa"} type="text" name={fidPrefix + "krajPodpisa"} className="validate" onChange={handleChange} required />
            <label htmlFor={fidPrefix + "krajPodpisa"}><Trans>field.label.krajPodpisa</Trans></label>
            <span className="helper-text" data-error={i18n.t('validation:field.validate.krajPodpisaRequired')} data-success=""></span>
        </div>
    );
}

