import React from 'react';


export default function Footer(props) {
    return (
        <footer className="page-footer indigo darken-4 eureko-footer">
            <div className="container">
                <div className="row">
                    <div className="col s12">
                        <div className="center-align eureko-logo-wrapper">
                            <a href="https://finmart.si/">
                                <img className="eureko-logo-finmart-clen" src="/img/finmart-logo-pokoncni-white.png" alt="Finmart Logo" />
                            </a>
                        </div>
                        <hr />
                    </div>
                </div>

                <div className="row">
                    <div className="col s12 m4">
                    </div>



                    <div className="col s12 m4">
                        <div className="col s12 center">
                            <h5>PODATKI PODJETJA</h5>
                        </div>
                        <div className="col s12 grey-text text-lighten-4 center">
                            Matična številka: 1990870000<br />
                            Davčna številka: 97684961<br />
                            ID za DDV: SI97684961<br />
                            Številka dovoljenja AZN: 30220 - 1282/06-4 z dne 31.8.2006
                        </div>
                    </div>

                    <div className="col s12 m4">
                    </div>

                </div>
            </div>
            <div className="footer-copyright">
                <div className="container">
                    <div className="row">
                        <div className="col s12 center">
                            &copy; {new Date().getFullYear()} FINMART
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );    
}

