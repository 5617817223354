import React from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
// import i18n from 'i18next';


export default function FormSubmitField(props) {
    const {t, i18n} = useTranslation(['translation', 'flashes']);

    let buttonText = <Trans>form.button</Trans>;
    let buttonClasses = 'waves-effect waves-light btn-large ';
    if (props.formStatus === 'SUBMITTING') {
        buttonText = <Trans>form.button.submitting</Trans>;
        buttonClasses += 'disabled';
    }

    return (
        <div className="row">
            <div className="input-field col s12 center-align">
                <button className={buttonClasses} type="submit" >
                    {buttonText}
                </button>
            </div>
        </div>
    );
}

