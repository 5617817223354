import { React, useState, useEffect, useRef, createRef } from 'react';
import M from '@materializecss/materialize';
import '@materializecss/materialize/dist/css/materialize.min.css';
import { useTranslation } from 'react-i18next';
import AgentLinkValidator from '../other/AgentLinkValidator';
import VstopSSvetovanjemStrankaPrisotnaForm from '../form/VstopSSvetovanjemStrankaPrisotnaForm';
import AgentService from '../service/AgentService';



export default function SklepanjeVstopSvetovalecRoute(props) {
    const [agentParamsStatus, setAgentParamsStatus] = useState('');
    const {t, i18n} = useTranslation();

    useEffect(() => {

        M.AutoInit();

    }, []);


    return (
        <AgentLinkValidator>
            <div className="container">
                <div className="row">
                    <div className="col s12 center">
                        <h4 className="finmart-form-title">Postopek sklepanja</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                            <VstopSSvetovanjemStrankaPrisotnaForm />
                    </div>
                </div>
            </div>
        </AgentLinkValidator>
    );
}
