export function parseFieldName(fieldName) {
    let i = fieldName.lastIndexOf('_');
    let result = {
        prefix: '',
        name: fieldName
    };

    if (i !== -1) {
        result.prefix = fieldName.slice(0, (i+1));
        i++;
        result.name = fieldName.slice(i);
    }

    return result;
}


export function getActionType(prefix, actionId) {
    let r = prefix.toUpperCase() + actionId.toUpperCase();
    return r;
}

export function inputValueToUpperCase(value) {
    if (typeof(value) === 'string') {
        value = value.toUpperCase();
    }

    return value;
}

export function formatDecimal(decimal) {
    console.debug(decimal);
    if (decimal === null || typeof (decimal) === 'undefined') {
        return '/';
    }

    let string = new String(decimal.toFixed(2));
    return string.replace('.', ',');
}

export function maybePopulateField(field, value) {
    console.debug("maybePopulateFieldmaybePopulateField");
    console.debug(field.current.type);
    switch(field.current.type) {
        case 'radio':
        case 'checkbox':
            if (value === field.current.value) {
                field.current.checked = true;
                field.current.focus();
            }
            
        default:
            if (typeof(value) === 'string' && value.length) {
                field.current.focus();
                field.current.value = value;
            }
    }
    

    return field;
}