import { React, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation, } from "react-router-dom";
import { useStore, useDispatch } from 'react-redux';
import AgentService from "../service/AgentService";
import Preloader from '../app.base/other/Preloader';
import MessageBox from '../app.base/other/MessageBox';
import { META_UPDATED } from '../redux/types/metaType';



export default function AgentLinkValidator(props) {
    const store = useStore();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [agentParamsStatus, setAgentParamsStatus] = useState(null);
    

    useEffect(() => {
        if (agentParamsStatus === null) {
            console.debug(window.location.search);
            const urlParams = new Proxy(new URLSearchParams(window.location.search), {
                get: (params, p) => params.get(p)
            });

            if (typeof (urlParams.h) === 'string' && typeof (urlParams.t) === 'string') {
                let agentParams = '?t=' + urlParams.t + '&h=' + urlParams.h;
                setAgentParamsStatus('SUBMITTING');

                let agentService = new AgentService();
                agentService.checkAgentLink({
                    h: urlParams.h,
                    t: urlParams.t
                }).then(result => {
                    console.debug("This is result:");
                    console.debug(result);
                    if (result.success) {
                        setAgentParamsStatus('VALID');
                        // props.setAgentStatus('VALID');
                    }
                    else {
                        setAgentParamsStatus('INVALID');
                    }

                    dispatch({
                        type: META_UPDATED,
                        payload: {
                            field: 'meta_agentParams',
                            value: agentParams
                        }
                    });
                })
                .catch()
                .then((result) => {
                    // Why do we need typeof check here?
                    if (typeof(result) !== 'undefined' && !result.success) {
                        setAgentParamsStatus('INVALID');
                        navigate('/neveljavna-povezava');
                    }
                });
            }
        }
        else if (agentParamsStatus === 'INVALID') {
            navigate('/neveljavna-povezava');
            dispatch({
                type: META_UPDATED,
                payload: {
                    field: 'meta_agentParams',
                    value: ''
                }
            });
        }
    }, [agentParamsStatus, navigate]);


    return (
        <>
            { agentParamsStatus === 'SUBMITTING' && 
                <MessageBox category="info" content="Preverjanje veljavnosti povezave..." /> && 
                <Preloader />
            }
            { agentParamsStatus === 'VALID' && props.children}
        </>
    )
}