import { React, useState, useEffect, useRef } from 'react';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
//import i18n from 'i18next';

/**
 * @note backend processing required:
 *  {
 *    tip: "STALEN",drzava: "Slovenija", kraj: "kraj", postnaStevilka: 1000, hisnStevilka: "1a", ulica: "ulica"
 *  }
 */
export default function MaslovField(props) {
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');

    const {t, i18n} = useTranslation(['translation', 'validation', 'flashes']);

    const handleChange = (event) => {
        props.onChange(event);
    } 

    return (
        <div className="row">
            <div className="input-field col s12">
                <input id={fidPrefix + "naslovUlica"} type="text" name={fidPrefix + "naslovUlica"} required className="validate" onChange={handleChange}  />
                <label htmlFor={fidPrefix + "naslovUlica"}>
                    <Trans>field.label.street</Trans>
                </label>
                <span className="helper-text" data-error={i18n.t('validation:field.validate.required')} data-success=""></span>
            </div>
            <div className="input-field col s6">
                <input id={fidPrefix + "naslovPosta"} type="text" name={fidPrefix + "naslovPosta"} required pattern="[0-9]{4}" className="validate" onChange={handleChange} />
                <label htmlFor={fidPrefix + "naslovPosta"}>
                    <Trans>field.label.post</Trans>
                </label>
                <span className="helper-text" data-error={i18n.t('validation:field.validate.postRequired')} data-success=""></span>
            </div>
            <div className="input-field col s6">
                <input id={fidPrefix + "naslovKraj"} type="text" name={fidPrefix + "naslovKraj"} required className="validate" onChange={handleChange} />
                <label htmlFor={fidPrefix + "naslovKraj"}>
                    <Trans>field.label.city</Trans>
                </label>
                <span className="helper-text" data-error={i18n.t('validation:field.validate.required')} data-success=""></span>
            </div>
        </div>

    );
    
}
