import { parseFieldName } from '../../app.base/redux/common';
import { PRIVOLITVENA_FINMART_PRIVOLITEV_UPDATED, PRIVOLITVENA_FINMART_PRIVOLITEV_Q33_UPDATED } from '../types/privolitvenaFinmartType';
import privolitvenaFinmartPrivolitevQ33Reducer from './privolitvenaFinmartPrivolitevQ33Reducer';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    }, fieldInfo;
    switch (action.type) {
        case PRIVOLITVENA_FINMART_PRIVOLITEV_UPDATED:
            fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;

        case PRIVOLITVENA_FINMART_PRIVOLITEV_Q33_UPDATED:
            let q33 = {};
            if (typeof (state.q33) !== 'undefined') {
                q33 = state.q33;
            }
            return { ...state, q33: privolitvenaFinmartPrivolitevQ33Reducer(q33, action) };
            
        default:
            return state;
    }
};