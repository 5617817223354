import { React, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';


export default function AgreeField(props) {
    
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');

    const {t, i18n} = useTranslation(['validation', 'flashes']); 

    const handleChange = (event) => {
        let dummyEvent = {
            target: {
                name: (fidPrefix + props.name),
                value: event.target.checked
            }
        };
        props.onChange(dummyEvent);
    }

    return (
        <p>
            <label htmlFor={fidPrefix + props.name} data-error={i18n.t('validation:field.validate.required')} data-success="bla bla">
                <input id={fidPrefix + props.name} name={fidPrefix + props.name} type="checkbox" className="validate" onChange={handleChange} required={props.isRequired} value="true" />
                <span>{props.text}</span>
            </label>
        </p>
    );
}


