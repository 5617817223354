import {React, useState, useEffect, useRef} from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';

export default function FormStepButtonField(props) {
    const [disableClass, setDisableClass] = useState('');
    const button = useRef();
    const {t, i18n} = useTranslation(['translation']);

    const handleClick = (event) => {
        props.onClick(event);
    };

    useEffect(() => {
        if ((props.role === 'previous' && props.currentStep === 1) || (props.role === 'next' && props.currentStep === props.allSteps.length)) {
            setDisableClass('disabled');
        }

        if ((props.role == 'previous' && props.currentStep > 1) || (props.role === 'next' && props.currentStep < props.allSteps.length) ) {
            setDisableClass('');
        }

    }, [props.allSteps, props.currentStep, props.role]);

    return (
        
        <div className="input-field center-align">
            <input type="button" ref={button} className={(props.role === 'next' ? "btn-small green darken-2" : "btn blue-grey darken-1") + disableClass} 
                onClick={handleClick} 
                value={props.role === 'next' ? t('translation:form.button.step.next') : t('translation:form.button.step.previous')}
            />            
        </div>
        
    );
}

