import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MessageBox from '../app.base/other/MessageBox';


export default function InvalidAgentLinkRoute(props) {
    return (
        <>
            
        <div className="row">
            <div className="col s12 center-align">
                <h4 className="finmart-form-title">Neveljavna povezava</h4>    
            </div>
        </div>
        <div className="row">
            <div className="col s6 offset-s3 center">
                <MessageBox category="error" content="Povezava za začetek sklepanja je neveljavna! Agent naj težave javi administratorjem platforme." />
            </div>
        </div>
        </>
    );
}
