import { parseFieldName } from '../../app.base/redux/common';
import naslovReducer from './naslovReducer';
import { ZAVAROVALEC_UPDATED, ZAVAROVALEC_NASLOV_UPDATED } from '../types/zavarovalecType';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    };
    switch (action.type) {
        case ZAVAROVALEC_UPDATED:
            let fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;

        case ZAVAROVALEC_NASLOV_UPDATED:
            let naslov = {};
            if (typeof (state.naslov) !== 'undefined') {
                naslov = state.naslov;
            }

            return { ...state, naslov: naslovReducer(naslov, action) };
        default:
            return state;
    }
};