export default function P404Route() {
    return (
        <div className="row">
            <div className="col s12 center">
                <h3 className="finmart-form-title">404</h3>
                <p>
                    Stran, ki ste jo iskali ne obstaja.
                </p>
            </div>
        </div>

    );  
} 