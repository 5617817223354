import { parseFieldName } from '../../app.base/redux/common';
import { PRIVOLITVENA_FINMART_PRIVOLITEV_Q33_UPDATED } from '../types/privolitvenaFinmartType';

const INITIAL_STATE = {
    klicPoTelefonu: false,
    posta: false,
    elektronskaPosta: false,
    SMS: false,
    drugePoti: false
};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    }, fieldInfo;
    switch (action.type) {

        case PRIVOLITVENA_FINMART_PRIVOLITEV_Q33_UPDATED:
            fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;
            
        default:
            return state;
    }
};