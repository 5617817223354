import { React, useState, useEffect, useRef } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { reduxSelectValueFor } from '../redux/selectors';
import { maybePopulateField } from '../redux/common';
//import i18n from 'i18next';

export default function DaNeField(props) {
    const store = useStore();
    const daInput = useRef(null);
    const neInput = useRef(null);
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const handleChange = (event) => {
        props.onChange(event);
    };

    useEffect(() => {
        let storeData = store.getState();
        maybePopulateField(
            daInput,
            reduxSelectValueFor(storeData, daInput)
        );

        maybePopulateField(
            neInput,
            reduxSelectValueFor(storeData, neInput)
        );

    }, []);

    return (
        <div className="row">
            <div className="input-field col s12">
                {props.question}
                <p>
                    <label>
                        <input ref={daInput} name={fidPrefix + props.stringId} type="radio" value="D" required={true} onChange={handleChange} />
                        <span>Da</span>
                    </label>
                    <label className="eureko-radio-label">
                        <input ref={neInput} name={fidPrefix + props.stringId} type="radio" value="N" required={true} onChange={handleChange} />
                        <span>Ne</span>
                    </label>
                </p>
                <span className="helper-text" data-error={t('validation:field.validate.daneFieldRequired')} data-success=""></span>
            </div>
        </div>
    );
}

