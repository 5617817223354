class ItemClen545Service {

    getUrl(endpoint) {
        let url = process.env.REACT_APP_API_URI;
        switch (endpoint) {
            case 'itemsClen545Create':
                url = url + '/items/clen545';
                break;
            default:
                url = null;
                break;
        }

        return url;

    }

    async sendItemClen545(formData) {
        let url = this.getUrl('itemsClen545Create');
        let response = await fetch(url, {
            'method': 'POST',
            'body': JSON.stringify(formData),
            'headers': new Headers({ 'content-type': 'application/json' })
        });

        return await response.json();

    }
}

export default ItemClen545Service;