class AgentService {
    getUrl(endpoint) {
        let url = process.env.REACT_APP_API_URI;
        switch (endpoint) {
            case 'agentsRead':
                url = url + '/agents';
                break;
            default:
                url = null;
                break;
        }

        return url;

    }

    /**
     * 
     * @param {object} contactInfo {email: "", mobitel: ""}
     */
    async checkAgentLink(queryParams) {
        let url = this.getUrl('agentsRead');
        let params = {
            t: typeof queryParams['t'] == 'string' ? queryParams['t'] : '',
            h: typeof queryParams['h'] == 'string' ? queryParams['h'] : ''
        };

        if (queryParams.hasOwnProperty('extLinks')) {
            params['extLinks'] = queryParams['extLinks'];
        }
        
        let result = {
            status: false,
            message: "Params missing."
        };

        // There is no point in calling - params are missing.
        if (!params.t.length || !params.h.length) {
            return result;
        }

        let query = new URLSearchParams();
        for (let key in params) {
            query.append(key, params[key]);
        }
        url = url + '?' + query.toString();

        try {

            let response = await fetch(url, {
                'method': 'GET',
                // 'body': JSON.stringify(requestObject)
            });

            result = await response.json();
            
        } catch (error) {
            console.debug("error ");
            console.debug(error);
            result = {
                status: false,
                errors: [{
                    code: 'CONNECTION_ERROR',
                    description: ''
                }],
                message: error.message
            };
        } finally {
            return result;
        }
        

    }

    async getExternalLinks(queryParams) {

        // Allow override.
        if (!queryParams.hasOwnProperty('extLinks')) {
            queryParams['extLinks'] = true;
        }

        return this.checkAgentLink(queryParams)
    }
}

export default AgentService;