import { parseFieldName } from '../../app.base/redux/common';
import { ROOT_LEGAL_UPDATED } from '../types/rootLegalType';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    };
    switch (action.type) {
        case ROOT_LEGAL_UPDATED:
            let fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;
        default:
            return state;
    }
};