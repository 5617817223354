import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AgentService from '../service/AgentService';
import GuardProdukt from '../other/GuardProdukt';
import LinkBox from '../other/LinkBox';
import { reduxSelectMetaAgentParams } from '../app.base/redux/selectors';

export default function TySklepanjeVstopSvetovalecRoute() {
    const selectAgentParams = useSelector(reduxSelectMetaAgentParams);
    const [extAgentLinks, setExtAgentLinks] = useState([]);

    useEffect(() => {
        const ALLOWED_LINKS = ['MC-SOGLASJE', 'WP-SVETOVALEC-MOZNOSTI'];
        const agentService = new AgentService();
        const urlParams = new URLSearchParams(selectAgentParams);


        agentService.getExternalLinks({
            h: urlParams.get('h'),
            t: urlParams.get('t'),
            extLinks: true
        })
            .then((result) => {
                if (result.success) {
                    let allLinks = result.data.agent.externalLinks, filtered = [], obj;
                    for (let k in allLinks) {

                        if (ALLOWED_LINKS.includes(allLinks[k].name)) {

                            obj = {
                                name: allLinks[k].name,
                                displayName: '',
                                imageLocation: '/img/mc-logo-blue-text.png',
                                url: allLinks[k].url
                            };

                            switch (allLinks[k].name) {
                                case 'MC-SOGLASJE':
                                    obj.displayName = 'E-sklepanje Medical Care';
                                    obj.imageLocation = '/img/allianz-logo-3.png';
                                    filtered.push(obj);
                                    break;
                                case 'WP-SVETOVALEC-MOZNOSTI':
                                    obj.displayName = 'E-sklepanje Dr Best';
                                    obj.imageLocation = '/img/dr-best-cgp-belo-ozadje.png';
                                    filtered.unshift(obj);
                                    break;
                            
                                default:
                                    break;
                            }

                        }
                    }

                    setExtAgentLinks(filtered);
                }
            })
    }, []);

    return (

        <>
            <GuardProdukt>
                <div className="row">
                    <div className="col s12 center">
                        <h4 className="finmart-form-title">Soglasje uspešno oddano</h4>
                    </div>
                </div>

                <div className="row">
                    {
                        extAgentLinks.map((link) => <LinkBox
                            displayName={link.displayName}
                            imageLocation={link.imageLocation}
                            url={link.url} />)
                    }

                    <LinkBox displayName="E-sklepanje Triglav" 
                        imageLocation="/img/zavarovalnica-triglav-logo-slogan-vertical-cymk.png" 
                        url="https://life.triglav.si/LifeCircle/"
                    />

                    <LinkBox displayName="E-sklepanje Sava"
                        imageLocation="/img/zavarovalnica-sava-logo-color.png"
                        url="https://sps.zav-sava.si/"
                    />

                    <LinkBox displayName="E-sklepanje Generali"
                        imageLocation="/img/zavarovalnica-generali-logo-big.svg"
                        url="https://sso.generali.si/idpel/Authn/UserPassword"
                    />

                    <LinkBox displayName="Sklepalni portal Dr Best"
                        imageLocation=""
                        url="https://sklepanje.dr-best.si/svetovalci"
                    />

                </div>
            </GuardProdukt>
        </>

    );
}
