import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Preloader from '../other/Preloader';


export default function PredogledArea(props) {
    const [pdfStatus, setPdfStatus] = useState('');
    const [timeoutReached, setTimeoutReached] = useState(true);

    const { t, i18n } = useTranslation(['translation']);


    const handlePreviewClick = (event) => {
        let formValidated = props.onPreviewClick(props.requestId);
        if (!formValidated) {
            setPdfStatus('');
        }

        setPdfStatus('LOADING');
        setTimeoutReached(false);
        setTimeout(() => setTimeoutReached(true), 15000);
        
    }

    useEffect(() => {
        let mounted = true;
        if (mounted && props.pdfContent !== null) {
            setPdfStatus('LOADED');
        }

        return () => {
            mounted = false;
        }
    }, [props.pdfContent]);

    return (
        <div className="row">
            <div className="col s12 center">
                <div className="card horizontal">
                    <div className="card-stacked ">
                        
                        <div className="card-content blue-grey darken-1 center-align">

                            { pdfStatus === '' && 
                                <p className="white-text">
                                    Za predogled dokumenta morate najprej izpolniti obrazec.
                                    <br /><br />
                                </p>
                            }

                            

                            {pdfStatus === 'LOADING' && <div>
                                <Preloader />
                                <br /><br />
                            </div> }

                            { pdfStatus === 'FAILED' &&
                                <p>
                                    <b className="red-text lighten-1">Pridobivanje dokumenta je spodletelo!</b>
                                    <br /><br />
                                </p>
                            }

                            { (pdfStatus === '' || pdfStatus === 'LOADED' || pdfStatus === 'FAILED' || timeoutReached === true) && <p>
                                <button className="waves-effect waves-light btn" type="button" onClick={handlePreviewClick}>
                                    {t('translation:form.button.predogledAreaFetchDoc')}
                                </button>
                                <br /><br />
                            </p> }
                            
                            {pdfStatus === 'LOADED' && <p className="white-text">Sledite 
                                <a href={'data:application/pdf;base64,' + props.pdfContent} download="predogled-dokumenta.pdf">povezavi</a>za prikaz dokumenta v novem zavihku.
                                <br /><br />
                            </p> }
                            
                            { pdfStatus === 'LOADED' &&
                                <iframe src={'data:application/pdf;base64,' + props.pdfContent} type="application/pdf" width="100%" height="800">
                                </iframe>
                            }


                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
} 