export default function DomovRoute() {
    return (
        <div className="row">
            <div className="col s12 center">
                <h4>Sklenite zavarovanje na platformi Finmart</h4>
                <p>
                    Za začetek sklepanja morate navezati stik z enim od naših agentov. Za več informaciji pokličite: 080 20 20
                </p>
            </div>
        </div>

    );
}
