import React from 'react';
import { Outlet } from 'react-router-dom';
import { Provider } from 'react-redux';
import './asset/materialize/sass/materialize.scss';
import './App.css';
import Header from './app.base/other/Header';
import Footer from './app.base/other/Footer';
import store from './redux/store';


/*  -------------------------------------- */

export default function App(props) {
    return (
        <Provider store={store}>
            <div className="app">
                <Header />
                    <main>
                        <Outlet />
                    </main>
                <Footer />
            </div>
        </Provider>

    );
    
}
