import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import { Provider } from 'react-redux';
import './index.css';
import DomovRoute from './route/DomovRoute';
import SklepanjeVstopSvetovalecRoute from './route/SklepanjeVstopSvetovalecRoute';
import P404Route from './route/P404Route';
import TySklepanjeVstopSvetovalecRoute from './route/TySklepanjeVstopSvetovalecRoute';
import InvalidAgentLinkRoute from './route/InvalidAgentLinkRoute';
//import store from './redux/store'
import App from './App';
import './i18n';
import './asset/font/ErasDemiITC.ttf';
import reportWebVitals from './reportWebVitals';


ReactDOM.render(
  <React.StrictMode>
  
      <BrowserRouter>
        <Suspense fallback="loading">
          <Routes>
            <Route path="/" element={ <App /> }>
              <Route path="/" element={<DomovRoute />} > </Route>
              
              <Route path="vstop-s-svetovalcem" element={ <SklepanjeVstopSvetovalecRoute /> }></Route>
              <Route path="zahtevek-za-sklepanje" element={ <TySklepanjeVstopSvetovalecRoute /> }></Route>
              <Route path="neveljavna-povezava" element={<InvalidAgentLinkRoute /> }></Route>
              <Route path="*" element={ <P404Route />} ></Route>
              
            </Route>
            
          </Routes>
        </Suspense>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
