import { React, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { parseFieldName, getActionType } from '../redux/common';
import { useTranslation } from 'react-i18next';


export default function PrenosPodatkovField(props) {
    console.debug("rerender PrenosPodatkovField");
    const dispatch = useDispatch();

    const [fidName, setFidName] = useState(props.name);
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const [kopirajPodatke, setKopirajPodatke] = useState(typeof(props.kopirajPodatke) === 'boolean' ? props.kopirajPodatke : false);
    
    const {t, i18n} = useTranslation(['validation', 'flashes']); 

    const triggerReduxUpdate = (event) => {
        // Find prefix.
        let fieldInfo = parseFieldName(event.target.name);
        let type = '';
        if (fieldInfo.prefix.length) {

            //type = prefix + '/updated';
            type = getActionType(fieldInfo.prefix, 'UPDATED');

            dispatch({
                type: type,
                payload: {
                    field: event.target.name,
                    value: event.target.value
                }
            });
        }
    };

    /**
     * If we select via name fun param return value can be array.
     * @param {*} selector 
     * @param {*} fun 
     * @returns 
     */
    const getElement = (selector, fun) => {
        let el = null;
        switch(fun) {
            case 'name':
                el = document.getElementsByName(selector);
                break;
            case 'id':
            default:
                el = document.getElementById(selector);
                break;
        }
         
        return el
    }

    const updateValue = (el, value) => {
        let inputEvent = new Event('input', {bubbles: true}), blurEvent = new Event('blur', {bubbles: true}), 
            focusEvent = new Event('focus', {bubbles: true});
        inputEvent.simulated = true;
        blurEvent.simulated = true;
        focusEvent.simulated = true;

        el.value = value;
        /**
         * Dirty quick fix so we do not trigger validation on initial rendering.
         * 
         * @todo How to do this better?
         */
        if (value.length) {
            
            el.dispatchEvent(inputEvent);
            el.dispatchEvent(blurEvent);
            el.dispatchEvent(focusEvent);
            //el.checkValidity();
        }
        
        let dummyEvent = {
            target: {
                name: el.name,
                value: el.value
            }
        };
        triggerReduxUpdate(dummyEvent);


    };

    const updateRadioValue = (srcEls, dstEls, allFalse=false) => {
        let changeEvent = new Event('change'), focusEvent = new Event('focus'), dummyEvent = null;
        for (let i = 0; i < srcEls.length; i++) {
            if (!allFalse) {
                dummyEvent = {
                    target: {
                        name: dstEls[i].name,
                        value: null
                    }
                };

                // We actually copied.
                dstEls[i].checked = srcEls[i].checked;
                if (dstEls[i].checked) {

                    // If we selected radio button we send actual value of that radio button 
                    // to redux.
                    dummyEvent.target.value = dstEls[i].value;
                    
                }
                
            }
            else {
                // We want to reset the state.
                dstEls[i].checked = false;

                // We do not really need this because this is all about redux update, but we 
                // want to be explicit and keep form and & redux in sync.
                dummyEvent = {
                    target: {
                        name: dstEls[i].name,
                        value: null
                    }
                };
            }

            dstEls[i].dispatchEvent(focusEvent);
            dstEls[i].dispatchEvent(changeEvent);

            /**
             * @todo Trigger redux action using DOM events.
             */
            console.debug(dstEls[i].name);
            console.debug(dummyEvent);
            triggerReduxUpdate(dummyEvent);

        }
    };

    const handleClick = (event) => {
        setKopirajPodatke(!kopirajPodatke);
    };

    const triggerCopy = () => {
        let dummyEvent = {
            target: {
                name: (fidPrefix + fidName),
                value: kopirajPodatke
            }
        };
        props.onChange(dummyEvent);

        let srcEl = null, fun;
        let value;
        for (let i = 0; i < props.mapFields.length; i++) {
            fun = 'id';
            if (typeof (props.mapFields[i].fun) === 'string') {
                fun = props.mapFields[i].fun;
            }

            value = '';
            if (kopirajPodatke && fun === 'id') {
                srcEl = getElement(props.mapFields[i].src, fun);
                //dstEl = getElement(props.mapFields[i].dst, fun);
                value = srcEl.value;

            }

            // most of inputs.
            if (fun === 'id') {
                updateValue(
                    getElement(props.mapFields[i].dst, fun),
                    value
                );
            }
            else {
                // Its radio or checkbox
                updateRadioValue(
                    getElement(props.mapFields[i].src, 'name'),
                    getElement(props.mapFields[i].dst, 'name'),
                    !kopirajPodatke
                );
            }
        }
    };

    // Copy on initial render e.g.: clen545 - ponudba
    useEffect(() => {
        if (kopirajPodatke) {
            triggerCopy();
        }
    }, []);

    // Copy when data is available (initial value for kopirajPodatke is true)
    useEffect(() => {
        if (props.kopirajPodatke) {
            triggerCopy();
        }
    });

    // Copy on switch click.
    useEffect(() => {
        triggerCopy();
    }, [kopirajPodatke]);

    return (
            <fieldset>
                <legend>{props.texts.legendText}</legend>
                <div className="switch">
                    <label>
                    {props.texts.neKopirajText}
                    <input 
                    type="checkbox" 
                    id={fidPrefix + fidName} 
                    name={fidPrefix + fidName} 
                    value="1" 
                    defaultChecked={kopirajPodatke ? 'checked' : ''}                    
                    onClick={handleClick} 

                    />
                        <span className="lever"></span>
                        {props.texts.kopirajText}
                    </label>
                </div>
            </fieldset>
    );
}


